<template>
	<div class="monitoring-coverage" v-loading="loadingFlagC1">
		<el-row>
			<el-col :span="7">
				<div v-loading="loadingFlagL1" class="content-box">
					<h4 class="title-h4">数据总量</h4>
					<div style="height: 480px;">
						<el-row :gutter="16" class="data-total" v-show="dataNum.length != 0">
							<el-col :span="12" v-for="(item,index) in dataNum" :key='index'>
								<div class="data-total-num">
									<p :style="item.color">
										<i :class="item.icon"></i>
										&nbsp;
										<span>{{item.tagName}}</span>
									</p>
									<p>
										<b>{{item.count}}</b> 家
									</p>
								</div>
							</el-col>
						</el-row>
						<!-- <el-row :gutter="16" v-show="dataNum.length == 0">
							<el-col :span="24">
								<img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 200px auto;" />
							</el-col>
						</el-row> -->
					</div>
				</div>
				<div v-loading="loadingFlagL2" class="content-box">
					<h4 class="title-h4">行业分布</h4>
					<div style="height: 312px;" id="echartsPieIndustry"></div>
				</div>
			</el-col>
			<el-col :span="10">
				<div class="content-box" style="margin: 10px 10px 0px 10px;position: relative;">
					<h4 class="title-h4">地域分布</h4>
					<div style="overflow: hidden;">
						<div id="echartsMap" style="height: 480px;"></div>
						<p class="mapFox">
							<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHand(item,index)">{{item.name}}&nbsp;&nbsp;</span>
						</p>
						<span class="iconfont icon-quanping6 mapLookBig" title="全屏" @click="mapFullOpen"></span>
					</div>
				</div>
				<div v-loading="loadingFlagC2" class="content-box" style="margin: 10px 10px 0px 10px;">
					<h4 class="title-h4">注册时间分布</h4>
					<div id="echartsLineArea" style="height: 312px;"></div>
				</div>
			</el-col>
			<el-col :span="7">
				<div class="content-box">
					<h4 class="title-h4">地域排行</h4>
					<div id="echartsBar" style="height: 480px;"></div>
				</div>
				<div v-loading="loadingFlagR2" class="content-box">
					<h4 class="title-h4">新增监测</h4>
					<div style="height: 312px;overflow: hidden;">
						<myScroll :data="newAddClue" :classify="classifyData"></myScroll>
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- 监测覆盖  地图  全屏 -->
		<el-dialog class="dialogVisibleScreenFull" :visible.sync="dialogVisible" :fullscreen="true" @close="mapFullClose">
			<div id="echartsMap_full" style="width: 100%;" :style="{ height: screenHeight + 'px' }"></div>
			<p class="mapFox">
				<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHandFull(item,index)">{{item.name}}&nbsp;&nbsp;</span>
			</p>
		</el-dialog>
	</div>
</template>

<script>
import myScroll from '../../components/my-scroll.vue'
import { getMapEname,echartsPieHuan,echartsMap2D,echartsLineArea,echartsBarHeng,echartsGauge } from "../../common/js/chart.js"
export default {
	name: "monitoring-coverage",
	components: { myScroll },
	data() {
		return {
			loadingFlagC1: false,
			loadingFlagC2: false,
			loadingFlagL1: false,
			loadingFlagL2: false,
			loadingFlagR2: false,
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
			mapFoxArr: [{
				"name": "中国",
				"param": "中国"
			}],
			dataNum: [], //数据总量    
			newAddClue: [], //新增监测
			classifyData: 'jiance',
			dialogVisible: false,
			screenHeight: 0,
		}
	},
	created() {
		//根据 用户名  默认显示
		let proviceName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).provinceName;
		let cityName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).cityName;
		if (proviceName_session != null && cityName_session == null) {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
		} else if (proviceName_session != null && cityName_session != null) {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
			this.mapFoxArr.push({
				"name": cityName_session,
				"param": cityName_session
			});
		}
	},
	mounted() {
		this.getBistributionMap("echartsMap"); //地域分布   累计预警
	},
	methods: {
		//加载地图  层级  下钻   返回   小屏
		mapClickHand(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		//加载地图  层级  下钻   返回   全屏
		mapClickHandFull(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		getChartMap(item, index) {
			if (index >= JSON.parse(sessionStorage.getItem("user_xjfj")).level) {
				if (index == 1) {
					this.mapFoxArr = [{
						"name": "中国",
						"param": "中国"
					}];
					this.mapFoxArr.push(item);
				} else if (index == 0) {
					this.mapFoxArr = [];
					this.mapFoxArr.push(item);
				}
				if (index != 2) {
					if (!this.dialogVisible) {
						this.getBistributionMap("echartsMap"); //地域分布   累计预警  小屏
					} else {
						this.getBistributionMap("echartsMap_full"); //地域分布   累计预警  全屏
					}
				}
			}
		},
		//地图  全屏  打开
		mapFullOpen() {
			this.dialogVisible = true;
			this.screenHeight = document.body.clientHeight - 30;
			this.getBistributionMap("echartsMap_full"); //地域分布   监测覆盖  全屏
		},
		//地图  全屏  关闭
		mapFullClose() {
			this.dialogVisible = false;
			this.getBistributionMap("echartsMap"); //地域分布   监测覆盖  小屏
		},
		//加载地图
		getBistributionMap(contanter) {
			this.loadingFlagC1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getCompanyArea', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: 0
				}
			}).then(res => {
				this.loadingFlagC1 = false; //loading 消失
				if (res.code == 200) {
				var data = res.data;
					let resultArr = [];
					for (var i = 0; i < data.length; i++) {
						resultArr.push({
							name: data[i].area,
							value: Number(data[i].count)
						})
					}
					//获取地图中文文字   转换   拼音名字
					let mapEname = getMapEname(getParam.name);
					echartsMap2D(contanter, mapEname, resultArr, this); //地域分布   地图
					let resultdata = [];
					for (let i = 0; i < data.length; i++) {
						resultdata.push({
							name: data[i].area,
							value: Number(data[i].count)
						})
					}
					echartsBarHeng("echartsBar", resultdata, "jc"); //地域排名
					this.overallSituationNumAll(1); //加载顶部tab 数  覆盖
					this.overallSituationNumAll(0); //加载顶部tab 数  预警
					this.getCountByType(); //数据总量
					this.getCompanyIndustry(); //行业分布
					this.getCountByInsertTime(); //新增监测企业注册时间变化趋势
					this.getWarnCompanyTen(); //新增监测企业top10
				}
			})
		},
		//加载顶部tab 数
		overallSituationNumAll(type) {
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/countNewCompanyInfoByTime', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: type
				}
			}).then(res => {
				if (type == 1) {
					this.$emit('accumulativeEarlyWarningNumChange', res.data);
				} else if (type == 0) {
					this.$emit('coverageMonitoringNumChange', res.data);
				}
			})
		},
		//数据总量
		getCountByType() {
			this.loadingFlagL1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getCountByType', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL1 = false; //loading 消失
				let data = res.data;
				for (let i = 0; i < data.length; i++) {
					if (data[i].tagName == "工商异常") {
						res.data[i]['icon'] = 'iconfont icon-jiaoyisuo';
						res.data[i]['color'] = 'background-color: #FF686F';
					} else if (res.data[i].tagName == "网站异常") {
						res.data[i]['icon'] = 'iconfont icon-wangzhan1';
						res.data[i]['color'] = 'background-color: #FFB22E';
					} else if (data[i].tagName == "舆情风险") {
						res.data[i]['icon'] = 'iconfont icon-yuqing1';
						res.data[i]['color'] = 'background-color: #269CF6';
					} else if (data[i].tagName == "经营异常") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #1DCC7F';
					} else if (data[i].tagName == "非法经营") {
						res.data[i]['icon'] = 'iconfont icon-feifachaxun';
						res.data[i]['color'] = 'background-color: #40EED5';
					} else if (data[i].tagName == "司法异常") {
						res.data[i]['icon'] = 'iconfont icon-sifa';
						res.data[i]['color'] = 'background-color: #AFA3F5';
					} else if (data[i].tagName == "高息诱导") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #FC79B4';
					} else if (data[i].tagName == "涉嫌传销") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #E3CBAA';
					} else if (data[i].tagName == "违规宣传") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #58D7FF';
					} else if (data[i].tagName == "关联风险") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #FFB22E';
					} else if (data[i].tagName == "立案企业") {
						res.data[i]['icon'] = 'iconfont icon-chengweihezuoshe';
						res.data[i]['color'] = 'background-color: #DD3333';
					}
				}
				this.dataNum = res.data.slice(0, 6);
			})
		},
		//行业分布
		getCompanyIndustry() {
			this.loadingFlagL2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getCompanyIndustry', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: 0
				}
			}).then(res => {
				this.loadingFlagL2 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						seriesData.push({
							value: Number(data[i].count),
							name: data[i].secondFormat
						});
						legendData.push(data[i].secondFormat);
					}
					let resultdata = {
						legendData: legendData,
						seriesData: seriesData,
					}
					let colorList = ['#FF686F', '#FFB22E', '#269CF6', '#1DCC7F', '#40EED5', '#AFA3F5'];
					echartsPieHuan("echartsPieIndustry", resultdata, colorList);
				}
			})
		},
		//新增监测企业注册时间变化趋势
		getCountByInsertTime() {
			this.loadingFlagC2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/countByInsertTime', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagC2 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						legendData.push(data[i].month);
						seriesData.push(Number(data[i].count));
					}
					echartsLineArea("echartsLineArea", legendData, seriesData, '#FFB922');
				}
			})
		},
		//新增监测企业top10
		getWarnCompanyTen() {
			this.loadingFlagR2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getWarnCompanyTen', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: 3
				}
			}).then(res => {
				this.loadingFlagR2 = false; //loading 消失
				let result = res.data;
				this.newAddClue = result;
			})
		},

		//获取 省市 等  参数
		getParam_fn() {
			let proviceName = "",
				cityName = "",
				name = "";
			let nameFox = [];
			this.mapFoxArr.filter((item, index) => {
				if (item.name != null) {
					nameFox.push(item);
				}
			})
			if (nameFox.length == 2) {
				proviceName = nameFox[1].name;
				cityName = "";
				name = nameFox[1].name;
				/* if(proviceName=="北京市" || proviceName=="天津市" || proviceName=="上海市" || proviceName=="重庆市"){
					cityName = proviceName;
				} */
			} else if (nameFox.length == 3) {
				proviceName = nameFox[1].name;
				cityName = nameFox[2].name;
				name = nameFox[2].name;
			} else {
				name = "中国";
			}
			let param = {
				proviceName: proviceName,
				cityName: cityName,
				name: name
			}
			//判断新疆兵团账号
			if (param.proviceName == "新疆兵团") {
				param.proviceName = "新疆维吾尔自治区";
			}
			return param;
		},

		// true:数值型的，false：非数值型
		isRealNum(val) {
			// isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
			if (val === "" || val == null) {
				return false;
			}
			if (!isNaN(val)) {
				//对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
				//所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
