<template>
	<div class="cumulative-warning" v-loading="loadingFlagC1">
		<el-row>
			<el-col :span="7">
				<div v-loading="loadingFlagL1" class="content-box">
					<h4 class="title-h4">区域风险指数</h4>
					<div id="echartsGauge" style="height: 250px;padding: 30px 0 0 0;box-sizing: border-box;"></div>
				</div>
				<div v-loading="loadingFlagL2" class="content-box">
					<h4 class="title-h4">风险等级分布</h4>
					<div style="height: 250px;" id="echartsPie"></div>
				</div>
				<div v-loading="loadingFlagL3" class="content-box">
					<h4 class="title-h4">企业预警指数TOP10</h4>
					<div style="height: 238px;overflow: hidden;">
						<myScroll :data="riskYujing"></myScroll>
					</div>
				</div>
			</el-col>
			<el-col :span="10">
				<div class="content-box" style="margin: 10px 10px 0px 10px;position: relative;">
					<h4 class="title-h4">地域分布</h4>
					<div style="overflow: hidden;">
						<div id="echartsMap" style="width: 100%;height: 554px;"></div>
						<p class="mapFox">
							<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHand(item,index)">{{item.name}}&nbsp;&nbsp;</span>
						</p>
						<span class="iconfont icon-quanping6 mapLookBig" title="全屏" @click="mapFullOpen"></span>
					</div>
				</div>
				<div v-loading="loadingFlagC2" class="content-box" style="margin: 10px 10px 0px 10px;">
					<h4 class="title-h4">预警企业变化趋势</h4>
					<div id="echartsLine" style="height: 238px;"></div>
				</div>
			</el-col>
			<el-col :span="7">
				<div class="content-box">
					<h4 class="title-h4">地域排行</h4>
					<div id="chartsBar" style="height: 250px;"></div>
				</div>
				<div v-loading="loadingFlagR2" class="content-box">
					<h4 class="title-h4">行业分布</h4>
					<div style="height: 250px;" id="echartsPieIndustry"></div>
				</div>
				<div v-loading="loadingFlagR3" class="content-box">
					<h4 class="title-h4">新增预警企业TOP10</h4>
					<div style="height: 238px;overflow: hidden;">
						<myScroll :data="newAddYujing"></myScroll>
					</div>
				</div>
			</el-col>
		</el-row>
		<!-- 累计预警  地图  全屏 -->
		<el-dialog class="dialogVisibleScreenFull" :visible.sync="dialogVisible" :fullscreen="true" @close="mapFullClose">
			<div id="echartsMap_full" style="width: 100%;" :style="{ height: screenHeight + 'px' }"></div>
			<p class="mapFox">
				<span class="mapClick" v-for="(item,index) in mapFoxArr" :key="index" ref="nameFox" :data-name="item.param" @click="mapClickHandFull(item,index)">{{item.name}}&nbsp;&nbsp;</span>
			</p>
		</el-dialog>
	</div>
</template>

<script>
import myScroll from '../../components/my-scroll.vue'
import { getMapEname,echartsPieRose,echartsPieHuan,echartsMap2D,echartsLine,echartsBarHeng,echartsGauge } from "../../common/js/chart.js"
export default {
	name: "cumulative-warning",
	components: { myScroll },
	data() {
		return {
			loadingFlagC1: false,
			loadingFlagC2: false,
			loadingFlagL1: false,
			loadingFlagL2: false,
			loadingFlagL3: false,
			loadingFlagR2: false,
			loadingFlagR3: false,
			mapFoxArr: [{
				"name": "中国",
				"param": "中国"
			}],
			levelrisk: {
				levelG: 0,
				levelZ: 0,
				levelD: 0,
				levelW: 0,
			},
			riskYujing: [], //风险预警
			newAddYujing: [], //新增预警
			dialogVisible: false,
			screenHeight: 0,
		}
	},
	created() {
		//根据 用户名  默认显示
		let proviceName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).provinceName;
		let cityName_session = JSON.parse(sessionStorage.getItem("user_xjfj")).cityName;
		if (proviceName_session != null && cityName_session == null) {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
		} else if (proviceName_session != null && cityName_session != null) {
			this.mapFoxArr.push({
				"name": proviceName_session,
				"param": proviceName_session
			});
			this.mapFoxArr.push({
				"name": cityName_session,
				"param": cityName_session
			});
		}
	},
	mounted() {
		this.getBistributionMap("echartsMap"); //地域分布   地图
	},
	methods: {
		//加载地图  层级  下钻   返回   小屏
		mapClickHand(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		//加载地图  层级  下钻   返回   全屏
		mapClickHandFull(item, index) {
			this.getChartMap(item, index); //判断生成几级地图
		},
		getChartMap(item, index) {
			if (index >= JSON.parse(sessionStorage.getItem("user_xjfj")).level) {
				if (index == 1) {
					this.mapFoxArr = [{
						"name": "中国",
						"param": "中国"
					}];
					this.mapFoxArr.push(item);
				} else if (index == 0) {
					this.mapFoxArr = [];
					this.mapFoxArr.push(item);
				}
				if (index != 2) {
					if (!this.dialogVisible) {
						this.getBistributionMap("echartsMap"); //地域分布   累计预警  小屏
					} else {
						this.getBistributionMap("echartsMap_full"); //地域分布   累计预警  全屏
					}
				}
			}
		},
		//加载地图  全屏  打开
		mapFullOpen() {
			this.dialogVisible = true;
			this.screenHeight = document.body.clientHeight - 30;
			this.getBistributionMap("echartsMap_full"); //地域分布   累计预警  全屏
		},
		//加载地图  全屏  关闭
		mapFullClose() {
			this.dialogVisible = false;
			this.getBistributionMap("echartsMap"); //地域分布   累计预警  小屏
		},
		//加载地图
		getBistributionMap(contanter) {
			this.loadingFlagC1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getCompanyArea', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: 1
				}
			}).then(res => {
				this.loadingFlagC1 = false; //loading 消失
				if (res.code == 200) {
					var data = res.data;
					let resultArr = [];
					for (var i = 0; i < data.length; i++) {
						resultArr.push({
							name: data[i].area,
							value: Number(data[i].count)
						})
					}
					//获取地图中文文字   转换   拼音名字
					let mapEname = getMapEname(getParam.name);
					echartsMap2D(contanter, mapEname, resultArr, this); //地域分布   地图
					let resultdata = [];
					for (let i = 0; i < data.length; i++) {
						resultdata.push({
							name: data[i].area,
							value: Number(data[i].count)
						})
					}
					echartsBarHeng("chartsBar", resultdata, 'yj'); //地域排名
					this.overallSituationNumAll(1); //加载顶部tab 数  覆盖
					this.overallSituationNumAll(0); //加载顶部tab 数  预警
					this.getAreaRiskAvg(); //区域风险指数
					this.getCountByMonthAndRisk(); //预警企业变化趋势
					this.getCountByRisk(); //风险等级分布
					this.getCompanyIndustry(); //行业分布
					this.getWarnCompanyTen(1); //企业预警指数top10
					this.getWarnCompanyTen(2); //新增预警企业top10
				}
			})
		},
		//加载顶部tab 数
		overallSituationNumAll(type) {
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/countNewCompanyInfoByTime', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: type
				}
			}).then(res => {
				if (type == 1) {
					this.$emit('accumulativeEarlyWarningNumChange', res.data);
				} else if (type == 0) {
					this.$emit('coverageMonitoringNumChange', res.data);
				}
			})
		},
		//区域风险指数
		getAreaRiskAvg() {
			this.loadingFlagL1 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getAreaRiskAvg', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL1 = false; //loading 消失
				if (res.code == 200) {
				let riskNum = [];
					riskNum.push({
						value: Number(res.data.risk),
						name: '风险指数',
					})
					echartsGauge("echartsGauge", riskNum);
				}
			})
		},
		//风险等级分布
		getCountByRisk() {
			this.loadingFlagL2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/countByRisk', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagL2 = false; //loading 消失
				if (res.code == 200) {
					var data = res.data;
					let resultdata = [];
					let namedata = ['高风险', '中风险', '低风险'];
					for (let i = 0; i < data.length; i++) {
						if (data[i].riskLevel == 1) {
							resultdata.push({
								value: Number(data[i].count),
								name: '高风险'
							});
						} else if (data[i].riskLevel == 2) {
							resultdata.push({
								value: Number(data[i].count),
								name: '中风险'
							});
						} else if (data[i].riskLevel == 3) {
							resultdata.push({
								value: Number(data[i].count),
								name: '低风险'
							});
						}
					}
					let colorList = ['#EE6666', '#FAC858', '#91CC75'];
					echartsPieRose("echartsPie", resultdata, namedata, colorList);
				}
			})
		},
		//行业分布
		getCompanyIndustry() {
			this.loadingFlagR2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getCompanyIndustry', {
				params: {
				provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: 1
				}
			}).then(res => {
				this.loadingFlagR2 = false; //loading 消失
				if (res.code == 200) {
					let data = res.data;
					let legendData = [];
					let seriesData = [];
					for (let i = 0; i < data.length; i++) {
						seriesData.push({
							value: Number(data[i].count),
							name: data[i].secondFormat
						});
						legendData.push(data[i].secondFormat);
					}
					let resultdata = {
						legendData: legendData,
						seriesData: seriesData,
					}
					let colorList = ['#FF686F', '#FFB22E', '#269CF6', '#1DCC7F', '#40EED5', '#AFA3F5'];
					echartsPieHuan("echartsPieIndustry", resultdata, colorList);
				}
			})
		},
		//预警变化趋势
		getCountByMonthAndRisk() {
			this.loadingFlagC2 = true; //loading 显示
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/countByMonthAndRisk', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
				}
			}).then(res => {
				this.loadingFlagC2 = false; //loading 消失
				if (res.code == 200) {
					let resultdata = {
						month: res.data.month,
						levelAll: res.data.levelAll,
						level1: res.data.level1,
						level2: res.data.level2,
						level3: res.data.level3,
					}
					echartsLine("echartsLine", resultdata);
				}
			})
		},
		//预警企业top10
		getWarnCompanyTen(type) {
			if (type == 1) {
				this.loadingFlagL3 = true; //loading 显示
			} else if (type == 2) {
				this.loadingFlagR3 = true; //loading 显示
			}
			let getParam = this.getParam_fn(); //获取 省市 等  参数
			this.$provider.get('/wg-ifrRisk/overallSituation/getWarnCompanyTen', {
				params: {
					provinceName: getParam.proviceName,
					cityName: getParam.cityName,
					type: type
				}
			}).then(res => {
				let result = res.data;
				if (type == 1) {
					this.loadingFlagL3 = false; //loading 消失
					this.riskYujing = result;
				} else if (type == 2) {
					this.loadingFlagR3 = false; //loading 消失
					this.newAddYujing = result;
				}
			})
		},

		//获取 省市 等  参数
		getParam_fn() {
			let proviceName = "",
				cityName = "",
				name = "";
			let nameFox = [];
			this.mapFoxArr.filter((item, index) => {
				if (item.name != null) {
					nameFox.push(item);
				}
			})
			if (nameFox.length == 2) {
				proviceName = nameFox[1].name;
				cityName = "";
				name = nameFox[1].name;
			} else if (nameFox.length == 3) {
				proviceName = nameFox[1].name;
				cityName = nameFox[2].name;
				name = nameFox[2].name;
			} else {
				name = "中国";
			}
			let param = {
				proviceName: proviceName,
				cityName: cityName,
				name: name
			}
			return param;
		},

		// true:数值型的，false：非数值型
		isRealNum(val) {
			// isNaN()函数 把空串 空格 以及NUll 按照0来处理 所以先去除，
			if (val === "" || val == null) {
				return false;
			}
			if (!isNaN(val)) {
				//对于空数组和只有一个数值成员的数组或全是数字组成的字符串，isNaN返回false，例如：'123'、[]、[2]、['123'],isNaN返回false,
				//所以如果不需要val包含这些特殊情况，则这个判断改写为if(!isNaN(val) && typeof val === 'number' )
				return true;
			} else {
				return false;
			}
		}
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
