<template>
	<div class="my-scroll">
		<div v-show="data.length != 0" @click.stop="linkOrgInfo($event)">
			<vue-seamless-scroll :data="data" :class-option="classOption" class="table-content">
				<ul class="scroll-list">
					<li v-for="(item,index) in data" :key="index">
						<span style="width: 10%;">
							<b v-if="index == 0" class="index-box index-box-one">{{item.serialNo}}</b>
							<b v-if="index == 1" class="index-box index-box-two">{{item.serialNo}}</b>
							<b v-if="index == 2" class="index-box index-box-thr">{{item.serialNo}}</b>
							<b v-else class="index-box">{{item.serialNo}}</b>
						</span>
						<span style="width: 50%;text-align: left;cursor: pointer;" :title="item.companyName" :data-name="item.companyName" :data-id="item.id">{{item.companyName}}</span>
						<span v-show="classify!='jiance'" style="width: 30%;">{{item.earlyWarningCheckTime}}</span>
						<span v-show="classify!='jiance'" style="width: 10%;" class="risk-box">{{item.risk}}</span>
						<span v-show="classify=='jiance'" style="width: 40%;">{{item.insertTime}}</span>
					</li>
				</ul>
			</vue-seamless-scroll>
		</div>
		<div v-show="data.length == 0">
			<!-- <img :src="`${baseUrl}img/bt-nodata-now.png`" alt="" style="display: block;margin: 100px auto;"> -->
		</div>
	</div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
	name: 'my-scroll',
	components: { vueSeamlessScroll },
	props: {
		data: {
			type: Array,
		},
		classify: {
			type: String,
		}
	},
	computed: {
		//无缝滚动
		classOption() {
			return {
				singleHeight: 23,
				waitTime: 0,
				step: 0.5,
				hoverStop: true
			}
		}
	},
	data() {
		return {
			baseUrl: this.$pulick.baseUrl, //默认路径目录  加载静态文件img
		}
	},
	created() {},
	methods: {
		linkOrgInfo(e) {
			let user_xjfj = JSON.parse(sessionStorage.getItem("user_xjfj"));
			user_xjfj['defaultActive'] = 1019;
			sessionStorage.setItem("user_xjfj", JSON.stringify(user_xjfj));
			const icardUrl = this.$router.resolve({
				path: '/institutionalPortraitInfo',
				query: {
					name: e.target.dataset.name,
					id: e.target.dataset.id
				}
			})
			window.open(icardUrl.href, '_blank'); //跳转新页面	 
		}
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
