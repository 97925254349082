<template>
	<div class="overall-situation">
		<div class="header-tab">
			<ul>
				<li>
					<div class="header-tab-name" :class="{'header-tab-active':currentSort==0}" @click="overallSituationActiveL">
						<div class="header-top-all">
							<span class="iconfont icon-jiance1"></span>
							<span>监测覆盖</span>
							<span>{{formatNumber(coverageMonitoringNum.all)}}</span>
						</div>
						<div class="header-top-num">
							<div>
								<p>本周新增</p>
								<b>{{formatNumber(coverageMonitoringNum.week)}}</b>
							</div>
							<div>
								<p>本月新增</p>
								<b>{{formatNumber(coverageMonitoringNum.month)}}</b>
							</div>
						</div>
					</div>
				</li>
				<li>
					<div class="header-tab-name" :class="{'header-tab-active':currentSort==1}" @click="overallSituationActiveR">
						<div class="header-top-all">
							<span class="iconfont icon-yujingtongji"></span>
							<span>累计预警</span>
							<span>{{formatNumber(accumulativeEarlyWarningNum.all)}}</span>
						</div>
						<div class="header-top-num">
							<div>
								<p>本周新增</p>
								<b>{{formatNumber(accumulativeEarlyWarningNum.week)}}</b>
							</div>
							<div>
								<p>本月新增</p>
								<b>{{formatNumber(accumulativeEarlyWarningNum.month)}}</b>
							</div>
						</div>
					</div>
				</li>
			</ul>
		</div>
		<div :is="currentView" @accumulativeEarlyWarningNumChange="accumulativeEarlyWarningNumFn" @coverageMonitoringNumChange="coverageMonitoringNumFn"></div>
	</div>
</template>

<script>
//组件  需单独的页面   监测预警  详情
import cumulativeWarning from '@/views/overall-situation/cumulative-warning.vue'
import monitoringCoverage from '@/views/overall-situation/monitoring-coverage.vue'
export default {
	name: "overall-situation",
	components: { cumulativeWarning,monitoringCoverage },
	data() {
		return {
			currentSort: 0,
			currentView: monitoringCoverage,
			//监测覆盖数
			coverageMonitoringNum: {
				all: 0,
				week: 0,
				month: 0,
			},
			//累计预警数
			accumulativeEarlyWarningNum: {
				all: 0,
				week: 0,
				month: 0,
			},
		}
	},
	created() {},
	mounted() {},
	methods: {
		//加载顶部tab 数
		accumulativeEarlyWarningNumFn(num) {
			this.accumulativeEarlyWarningNum = num;
		},
		coverageMonitoringNumFn(num) {
			this.coverageMonitoringNum = num;
		},
		//累计预警  tab 切换
		overallSituationActiveL() {
			this.currentSort = 0;
			this.currentView = monitoringCoverage;
		},
		//监测覆盖  tab 切换
		overallSituationActiveR() {
			this.currentSort = 1;
			this.currentView = cumulativeWarning;
		},
		//数字转化
		formatNumber(nStr) {
			nStr += '';
			let x = nStr.split('.');
			let x1 = x[0];
			let x2 = x.length > 1 ? '.' + x[1] : '';
			let rgx = /(\d+)(\d{3})/;
			while (rgx.test(x1)) {
				x1 = x1.replace(rgx, '$1' + ',' + '$2');
			}
			return x1 + x2;
		},
	}
}
</script>

<style lang="stylus" scoped="scoped"></style>
